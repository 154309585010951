<template>
    <section class="home">
        <div class="container position-relative pt-0 pt-md-5 pb-5 mt-4 mt-md-5">
            <div class="row align-items-center">
                <div class="col-lg-8 order-1 order-lg-0 h-100">
                    <div class="title-wrapper position-relative d-flex justify-content-center mb-3"
                         data-aos="fade-up"
                         data-aos-duration="1000"
                         data-aos-delay="1000">
                        <div class="title position-relative d-none d-md-block">
                            <div class="logo position-absolute">
                                <svg id="Isolation_Mode" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96.3158 36.6237">
                                    <path fill="#ea69a3" d="M56.7612,12.3776q0-4.7341.0009-9.4683c0-.1648-.0452-.3768.0382-.479.065-.0795.2974-.0255.4555-.0255,2.6675,0,5.335-.0033,8.0025.0053a10.4868,10.4868,0,0,1,1.6152.0854,7.6057,7.6057,0,0,1,3.6932,1.4866A5.1255,5.1255,0,0,1,72.4436,7.1a6.9862,6.9862,0,0,1,.0373,2.24,4.917,4.917,0,0,1-.9941,2.4509,5.7531,5.7531,0,0,1-1.993,1.6425,10.61,10.61,0,0,1-2.1123.7469c-.2057.05-.21.07-.0852.2379q.599.8094,1.208,1.6113c.412.5454.8284,1.0876,1.24,1.6333q1.0583,1.4022,2.1125,2.8075c.4257.5687.8457,1.1418,1.2655,1.7149a.8322.8322,0,0,1,.0738.1679.8968.8968,0,0,1-.1528.0263c-.7659.0011-1.5318-.0047-2.2977.0023a.48.48,0,0,1-.423-.2328q-2.4162-3.2431-4.8419-6.4794c-.2127-.2845-.4258-.5689-.6319-.8582a.52.52,0,0,0-.4821-.2025c-1.6771.0063-3.3542.0034-5.0313.0055-.3363.0005-.34.0051-.34.3562q-.0014,3.4467,0,6.8933c0,.066.001.1321,0,.1981-.0059.3146-.0061.3187-.3006.32-.5941.0026-1.1884.0009-1.7826,0-.09,0-.1673.0144-.167-.1393q.01-4.932.0049-9.8639ZM58.9951,8.59h.0008q0,1.782,0,3.5641c0,.1056-.0065.2119.004.3165.0038.038.0464.1015.0745.1033.3268.0215.6541.0444.9814.0457,1.3332.0053,2.6665,0,4,.005a13.6262,13.6262,0,0,0,2.3586-.1484,5.8831,5.8831,0,0,0,2.4281-.9771,3.3137,3.3137,0,0,0,1.0226-1.24A4.1591,4.1591,0,0,0,70.11,7.2974,3.3506,3.3506,0,0,0,68.1167,5.043a7,7,0,0,0-2.959-.5645c-1.9138-.021-3.8281-.005-5.7421-.0043-.0792,0-.1586.0033-.2376,0-.14-.0064-.1862.0686-.1826.1953.0023.0792,0,.1584,0,.2376Q58.9951,6.7483,58.9951,8.59Z" transform="translate(-1.5128 -1.7744)"></path>
                                    <path fill="#ea69a3" d="M95.1145,14.5369q-1.1886,0-2.3772.0008c-.0778,0-.1556.0122-.2334.0187.0248.0675.0457.1367.075.2022q.6351,1.4192,1.2723,2.8373.8649,1.9271,1.7309,3.8537c.1085.2405.2336.4734.3451.7126.08.1719.058.2137-.1251.2152-.4887.0041-.9774.0009-1.466-.0022-.2373-.0016-.5309.0742-.6965-.0372-.18-.1209-.2505-.4144-.3514-.64-.6331-1.418-1.26-2.8387-1.8917-4.2574-.3945-.8865-.8014-1.7676-1.1832-2.6594a.3478.3478,0,0,0-.3833-.246c-1.0565.0073-2.1131.0031-3.17.0032q-2.3971,0-4.7942.0011c-.3862,0-.3746.0069-.5363.3753-.3123.7115-.643,1.4149-.9591,2.1248-.5832,1.31-1.16,2.6218-1.7423,3.9317-.1762.3962-.3631.7876-.5417,1.1827a.3154.3154,0,0,1-.3213.2315c-.6206-.0107-1.2414-.0015-1.8621-.0066-.1859-.0015-.2051-.0374-.1279-.2122q.3434-.7773.6939-1.5514.8667-1.9248,1.7356-3.8487c.4457-.9827.9-1.9617,1.3465-2.9438q.7841-1.7227,1.5625-3.448.6837-1.512,1.3651-3.025c.3146-.695.6344-1.3878.9486-2.0831.4166-.9217.8345-1.8429,1.2409-2.7691a.3546.3546,0,0,1,.3824-.2545c.5146.0142,1.03.0179,1.5448.0021a.3927.3927,0,0,1,.427.2641c.2591.59.529,1.175.7937,1.7624q.6964,1.5459,1.3916,3.0922Q89.8734,8.84,90.54,10.3155c.3135.6934.6367,1.3825.9388,2.0807a.5228.5228,0,0,0,.5719.3142c1.5452-.0046,3.09-.0021,4.6356-.002.3038,0,.6077.0061.9112-.002.1724-.0047.2332.0673.2309.2339-.0064.449.0006.8981-.0063,1.3471-.0036.233-.0144.245-.2513.2464-.8188.0045-1.6376.0017-2.4565.0017Zm-6.3639-1.8232c.1049-.0081.21-.0167.3147-.0243.13-.0095.22-.0181.1329-.2-.2435-.5109-.4675-1.0312-.6958-1.5493-.4667-1.0586-.929-2.1191-1.3973-3.177-.346-.7817-.6982-1.5608-1.0484-2.3407-.0748-.1666-.1531-.3317-.23-.4975-.0857.17-.1772.3369-.2561.51-.5616,1.23-1.1259,2.4583-1.6788,3.692-.49,1.0931-.9666,2.1921-1.4483,3.2888-.1119.2549-.1093.2552.1925.2277a2.2631,2.2631,0,0,1,.2769-.0038c.6838.0215,1.3675.0589,2.0514.0639Q86.8572,12.7174,88.7506,12.7137Z" transform="translate(-1.5128 -1.7744)"></path>
                                    <path fill="#ea69a3" d="M15.8816,8.91q0-3.0506,0-6.1012c0-.41.0013-.41.41-.41.5414.0005,1.0829-.0009,1.6243.0035.2549.0021.2549.0074.2624.27.0007.0264,0,.0528,0,.0792,0,3.843.0071,7.686-.0056,11.5289a9.8087,9.8087,0,0,1-.9311,4.2444,6.8854,6.8854,0,0,1-2.6879,2.98A8.0682,8.0682,0,0,1,11.56,22.5589a10.8811,10.8811,0,0,1-2.3224.1284,8.4552,8.4552,0,0,1-4.4235-1.3934,7.2981,7.2981,0,0,1-2.9048-4.2011A11.716,11.716,0,0,1,1.5194,13.89q.01-5.4474.0026-10.895c0-.0924-.0016-.1849,0-.2773.0066-.31.0071-.3151.302-.3175q.8517-.0069,1.7036-.0026c.276.0018.2849.0164.2947.2927.0033.0923.0006.1849.0006.2773q0,5.4674-.003,10.9346a8.9527,8.9527,0,0,0,.3329,2.6141,5.2447,5.2447,0,0,0,3.8342,3.8327,8.5488,8.5488,0,0,0,2.5114.201,5.6963,5.6963,0,0,0,2.8431-.85,4.9927,4.9927,0,0,0,2.0394-2.5449A7.3509,7.3509,0,0,0,15.88,14.417c-.0074-1.8357-.0021-3.6713-.0021-5.507Z" transform="translate(-1.5128 -1.7744)"></path>
                                    <path fill="#ea69a3" d="M34.1855,32.5633c0,1.4524-.0031,2.9049.0013,4.3574a1.3251,1.3251,0,0,1-.9377,1.3506,1.2829,1.2829,0,0,1-1.54-1.1231c.0205-1.1617.005-2.3239.0049-3.4859,0-.66.0017-1.3205-.0008-1.9806a.414.414,0,0,0-.0455-.1277.41.41,0,0,0-.0846.0744c-.67,1.0417-1.3275,2.0912-2.0117,3.1232a1.2672,1.2672,0,0,1-.8966.65,1.1247,1.1247,0,0,1-1.151-.5193c-.6354-.9376-1.2308-1.9024-1.8466-2.8534-.0914-.1411-.2064-.2668-.31-.4a4.43,4.43,0,0,0-.0512.478c-.0036,1.6506,0,3.3011-.0028,4.9517a1.1217,1.1217,0,0,1-.8876,1.195,1.1965,1.1965,0,0,1-1.4786-.78,2.2446,2.2446,0,0,1-.0909-.6217q-.0085-4.2981-.0012-8.5961a1.366,1.366,0,0,1,.5768-1.194.8137.8137,0,0,1,.5135-.1748,1.1174,1.1174,0,0,0,.1979-.0048,1.47,1.47,0,0,1,1.5484.8471c.8958,1.4905,1.8163,2.9662,2.7266,4.4481.1122.1824.1153.183.217.0176q1.4077-2.2888,2.818-4.576a2.2577,2.2577,0,0,1,.3417-.4315.9624.9624,0,0,1,.7467-.3023,3.92,3.92,0,0,1,.8222.0558,1.1978,1.1978,0,0,1,.8169,1.2252c.0055,1.1488.0019,2.2976.002,3.4463q0,.4754,0,.9508Z" transform="translate(-1.5128 -1.7744)"></path>
                                    <path fill="#ea69a3" d="M48.137,32.5857c0,1.4918-.0085,2.9836.0048,4.4752a1.219,1.219,0,0,1-.826,1.1723,1.2529,1.2529,0,0,1-1.6479-1.1419c-.01-.2107-.0016-.4223-.0016-.6335V31.8637c0-.0792.0016-.1585-.0012-.2376a.2864.2864,0,0,0-.0218-.0713.26.26,0,0,0-.0578.0484c-.3438.53-.6852,1.0622-1.03,1.5922s-.6775,1.0675-1.0428,1.5825a1.2962,1.2962,0,0,1-.87.6288.9928.9928,0,0,1-.7461-.1525,1.9636,1.9636,0,0,1-.4922-.5068c-.6114-.9209-1.204-1.8543-1.8063-2.7813-.073-.1124-.1642-.213-.247-.3191-.006.1309-.0173.2618-.0174.3927q-.002,2.4357.0008,4.8714a1.496,1.496,0,0,1-.258.8942,1.2125,1.2125,0,0,1-1.37.4375,1.2708,1.2708,0,0,1-.8434-1.1649q.0183-4.495.0024-8.99a1.3094,1.3094,0,0,1,1.2-1.2212,1.6508,1.6508,0,0,1,1.6619.9448c.56.9837,1.1821,1.9319,1.7762,2.8962q.4664.757.9265,1.518c.0747.1232.1157.1.1835-.0117q1.1934-1.9563,2.3951-3.9075c.18-.2916.3755-.5731.5543-.8652a1.087,1.087,0,0,1,1.0537-.5546c.079.0041.1584-.0007.2376,0A1.1612,1.1612,0,0,1,48.1372,28.15q.0045,2.2178.0009,4.4357Z" transform="translate(-1.5128 -1.7744)"></path>
                                    <path fill="#ea69a3" d="M45.9045,13.4508q0,4.2381,0,8.4763c0,.47.0471.4539-.4471.4524q-.6931-.0023-1.3863.0013c-.5031.0021-.4588.03-.4623-.48-.0021-.3036,0-.6073,0-.911q0-8.0207,0-16.0415c0-.52.0558-.4728-.46-.4731q-2.931-.0021-5.8621,0c-.417,0-.4164.0337-.4132-.4049.0034-.4753,0-.9506.0043-1.4258.002-.2326.0067-.2328.2471-.2416.04-.0014.0792,0,.1188,0l15.0512-.0024c.04,0,.0794.003.1188,0,.1644-.011.25.0571.2379.2294-.0036.0525,0,.1056,0,.1584,0,.4357.0012.8714-.001,1.307-.0019.3754-.0037.3759-.3631.3779-.1716.001-.3433-.0008-.5149-.0007q-2.6538,0-5.3075.0007c-.0132,0-.0264,0-.04,0-.5725.0043-.5187-.0559-.5189.5Q45.9032,9.2127,45.9045,13.4508Z" transform="translate(-1.5128 -1.7744)"></path>
                                    <path fill="#ea69a3" d="M23.4837,12.3791q0-4.7535,0-9.5071c0-.5015-.0364-.4741.47-.4718.5282.0023,1.0564-.0018,1.5845.0031.2363.0021.2378.01.2464.2457.0023.0659,0,.132,0,.198q0,8.4773-.0006,16.9543c0,.4724-.0468.4432.454.4433q5.1695.0011,10.3389.0009c.4816,0,.46-.0408.46.4405-.0007.4753.0032.9507-.0022,1.426-.0029.2575-.01.2583-.2721.2677-.0923.0032-.1848.0006-.2773.0007l-12.5572.0031h-.04c-.4013-.001-.4041-.001-.4042-.4182Q23.4831,17.1723,23.4837,12.3791Z" transform="translate(-1.5128 -1.7744)"></path>
                                    <path fill="#ea69a3" d="M12.0668,32.5354c0-1.4258-.0028-2.8515.0016-4.2773A1.253,1.253,0,0,1,12.9072,27a2.1745,2.1745,0,0,1,.62-.0975c1.9538-.0072,3.9077-.0027,5.8615-.0064a1.557,1.557,0,0,1,.7926.2068,1.0892,1.0892,0,0,1-.5176,2.0225c-1.5182.0043-3.0364.0017-4.5545.0017-.0924,0-.1853.0059-.2772-.0013-.1717-.0135-.234.0635-.2325.2314.0051.5808-.0006,1.1617.0031,1.7426.002.3.007.3059.3093.3067q1.8217.0047,3.6436.0032c.1188,0,.2381-.0056.3563.0035a1.1254,1.1254,0,0,1,1.0005.7242,1.101,1.101,0,0,1-.9868,1.4957c-.1443.012-.29.004-.4355.0041q-1.7822.0017-3.5644.0039c-.3191.0009-.3226.0066-.324.3331q-.0038.8516.001,1.703c.002.3161.0061.32.3355.32q2.1387.0025,4.2773.0012c.1584,0,.3169-.0012.4753.0026a1.1015,1.1015,0,0,1,1.1043,1.0622,1.147,1.147,0,0,1-1.0673,1.1591c-.2364.0166-.475.0019-.7126.0019-1.7822,0-3.5645.0056-5.3467-.0038a2.5583,2.5583,0,0,1-.8133-.1168,1.186,1.186,0,0,1-.782-1.1726c-.025-.4872-.0064-.9767-.0066-1.4651Q12.0664,34.0009,12.0668,32.5354Z" transform="translate(-1.5128 -1.7744)"></path>
                                    <path fill="#ea69a3" d="M50.8332,32.7946c0-1.1353-.0061-2.2707.0028-3.406.004-.5.0316-.9992.0616-1.498a1.1357,1.1357,0,0,1,.7927-.8991,2.2177,2.2177,0,0,1,.5829-.0868q2.9109-.0091,5.8218.0009a2.2059,2.2059,0,0,1,.62.0981A1.11,1.11,0,0,1,59.47,28.053a1.0843,1.0843,0,0,1-.77,1.04,1.6112,1.6112,0,0,1-.4316.0294q-2.2575.0027-4.5149.0012c-.46,0-.4687.0014-.46.4564.0085.4469.0372.8934.0559,1.34a1.126,1.126,0,0,1-.0046.158c-.0328.33-.0333.3357.3034.336q1.8812.0018,3.7623-.0053a1.4514,1.4514,0,0,1,.727.1446,1.0821,1.0821,0,0,1,.5811,1.1866,1.1131,1.1131,0,0,1-1.0657.8938c-.1575.0116-.3167-.0011-.4751-.0011q-1.703,0-3.4059.0008a2.7148,2.7148,0,0,0-.3554.0158.1409.1409,0,0,0-.0979.0919c-.0152.2994-.0284.5995-.0254.8991.004.4062.0193.8123.04,1.2179a.1779.1779,0,0,0,.1067.1308,1.9852,1.9852,0,0,0,.3548.0154c1.5314.001,3.0628.0043,4.5941-.0007a1.1012,1.1012,0,0,1,.506,2.1.9131.9131,0,0,1-.3844.0533c-1.5181.004-3.0363-.0016-4.5544.0069-.5261.0029-1.0518.0436-1.5779.0559a1.8842,1.8842,0,0,1-.9169-.1858,1.1146,1.1146,0,0,1-.5467-.6956,2.226,2.226,0,0,1-.0788-.6627C50.83,35.3821,50.8331,34.0883,50.8332,32.7946Z" transform="translate(-1.5128 -1.7744)"></path>
                                    <path fill="#ea69a3" d="M91.5415,26.7091a4.976,4.976,0,0,1,3.522,1.4363,5.618,5.618,0,0,1,1.21,2.0026,8.1645,8.1645,0,0,1,.4313,2.5411,6.4506,6.4506,0,0,1-.0936.899,6.077,6.077,0,0,1-.3839,1.5192,5.4542,5.4542,0,0,1-.503,1.0285,4.6447,4.6447,0,0,1-2.437,1.9714,4.2743,4.2743,0,0,1-2.17.2249,7.1137,7.1137,0,0,1-.8874-.1587,4.7958,4.7958,0,0,1-2.4608-1.8125,5.4242,5.4242,0,0,1-.837-1.8128,4.2879,4.2879,0,0,1-.2136-1.2766c.0207-.4309-.0113-.8644.0092-1.2953A5.7,5.7,0,0,1,88.256,28.228a4.5849,4.5849,0,0,1,2.0868-1.2986A10.7906,10.7906,0,0,1,91.5415,26.7091ZM94.2,32.4606c-.0441-.2515-.1136-.6373-.1789-1.0238a3.1843,3.1843,0,0,0-.9846-1.9219,2.0313,2.0313,0,0,0-2.9276.1613,3.5792,3.5792,0,0,0-.6743,1.2434,11.2982,11.2982,0,0,0-.2438,1.5531.5192.5192,0,0,0,.0137.1566c.0191.1694.0483.3383.0562.5082a3.559,3.559,0,0,0,.9659,2.3962,1.9133,1.9133,0,0,0,1.483.6273,2.0839,2.0839,0,0,0,1.7082-.9106A5.2283,5.2283,0,0,0,94.2,32.4606Z" transform="translate(-1.5128 -1.7744)"></path>
                                    <path fill="#ea69a3" d="M76.0056,32.9379a9.0367,9.0367,0,0,1,.43-2.9122,4.5565,4.5565,0,0,1,2.335-2.8034,5.0789,5.0789,0,0,1,2.9936-.4244,6.3027,6.3027,0,0,1,1.7649.5291,1.3257,1.3257,0,0,1,.7966,1.0991,1.0335,1.0335,0,0,1-.375.8785,1.1268,1.1268,0,0,1-1.3558.1616,4.5635,4.5635,0,0,0-1.3721-.4628,2.1276,2.1276,0,0,0-2.2356,1.1732,5.6246,5.6246,0,0,0-.3724,1.2338.4658.4658,0,0,0,.019.1334.714.714,0,0,0,.1078-.0335c.2354-.1158.4633-.25.7057-.3476a3.7607,3.7607,0,0,1,1.7716-.295,4.1766,4.1766,0,0,1,2.7145,1.0932,3.033,3.033,0,0,1,.8973,1.8407,4.7607,4.7607,0,0,1,.066.7433,3.7225,3.7225,0,0,1-2.3321,3.4474,5.3389,5.3389,0,0,1-1.3658.3424,5.5623,5.5623,0,0,1-1.6465-.0335,3.9167,3.9167,0,0,1-2.2967-1.2,4.66,4.66,0,0,1-1.1379-2.5129A15.6572,15.6572,0,0,1,76.0056,32.9379Zm4.4936,3.3074c.0792,0,.1585.0006.2376,0a1.6534,1.6534,0,0,0,1.6779-1.3792,1.4984,1.4984,0,0,0-.5628-1.5067A2.1244,2.1244,0,0,0,81.0731,33a2.16,2.16,0,0,0-1.7216.18,1.5992,1.5992,0,0,0-.2775,2.6079A1.8683,1.8683,0,0,0,80.4992,36.2453Z" transform="translate(-1.5128 -1.7744)"></path>
                                    <path fill="#ea69a3" d="M70.0716,26.9024c.7792,0,1.5584-.0065,2.3375.0034a4.8645,4.8645,0,0,1,.8227.0763,1.0739,1.0739,0,0,1,.8518,1.362,1.3967,1.3967,0,0,1-.3383.6528c-.7521.85-1.6287,1.5716-2.4167,2.3837-.0344.0355-.0661.0737-.099.1106a.6244.6244,0,0,0,.1586.0743,4.0407,4.0407,0,0,1,1.637.6521,2.47,2.47,0,0,1,.9923,1.3,3.664,3.664,0,0,1-.442,3.2813,3.39,3.39,0,0,1-1.9515,1.38,5.9838,5.9838,0,0,1-3.3449-.0412,4.8818,4.8818,0,0,1-1.8578-1.0135,1.1531,1.1531,0,0,1-.4475-.9731,1.2,1.2,0,0,1,.9-1.0852,1.2866,1.2866,0,0,1,1.23.3618,3.0594,3.0594,0,0,0,2.2275.73,2.1105,2.1105,0,0,0,.7845-.2352,1.31,1.31,0,0,0-.06-2.2819,2.0493,2.0493,0,0,0-.8672-.24,6.9185,6.9185,0,0,0-.8977-.0478,1.1231,1.1231,0,0,1-1.1518-.8357.9976.9976,0,0,1,.1739-.86,3.6037,3.6037,0,0,1,.4563-.5433c.5737-.5839,1.1555-1.16,1.7337-1.7393a1.5584,1.5584,0,0,0,.1613-.1741c.0683-.0934.0237-.1454-.0785-.153-.0919-.0068-.1847-.0035-.2771-.0035-.9641-.0005-1.9281.0014-2.8922-.0019A1.0452,1.0452,0,0,1,67,27.0144a1.8491,1.8491,0,0,1,.576-.107C68.4074,26.8959,69.24,26.9025,70.0716,26.9024Z" transform="translate(-1.5128 -1.7744)"></path>
                                    <path fill="#ea69a3" d="M1.5216,32.6065c0-1.4656.0026-2.9313-.0012-4.397a1.2089,1.2089,0,0,1,.5154-1.0566,1.5146,1.5146,0,0,1,.8956-.2557c1.3072.0032,2.6145.0014,3.9217.0013.713,0,1.4261-.0061,2.1391.0018a1.1918,1.1918,0,0,1,1.1916.86,1.1229,1.1229,0,0,1-1.008,1.4353c-.1179.0109-.2375.0048-.3563.0048q-2.1589.0008-4.3179.001c-.0924,0-.1853.0062-.2772-.0009-.172-.0133-.231.0629-.2292.2317.0064.6073-.0008,1.2148.0048,1.8222.0027.2917.0241.3132.3213.3141,1.1752.0033,2.35.0011,3.5256.0008.1584,0,.3169-.0046.4753-.0011a1.1043,1.1043,0,0,1,1.18,1.1247A1.13,1.13,0,0,1,8.4,33.8666a4.0307,4.0307,0,0,1-.5146.0044q-1.7428,0-3.4859-.0009c-.4459-.0006-.4009-.005-.4015.4152-.0015.9243-.0247,1.8494.0065,2.7727a1.2078,1.2078,0,0,1-1.24,1.2523A1.2872,1.2872,0,0,1,1.513,37.0431C1.5386,35.5646,1.5214,34.0854,1.5216,32.6065Z" transform="translate(-1.5128 -1.7744)"></path>
                                    <path fill="#ea69a3" d="M97.82,2.441c0,.1844.0052.369-.0043.5529-.002.0377-.0731.1082-.0882.1023-.05-.02-.1252-.0652-.128-.1051-.0156-.2231-.0112-.4476-.0182-.6715-.0009-.0287-.0284-.0566-.0435-.0849a.7268.7268,0,0,1-.0526.0541c-.1366.114-.1454.4073-.3508.3708-.1185-.021-.1986-.2539-.2983-.39-.0141-.0192-.0554-.0443-.06-.04-.0235.0218-.0548.0514-.0555.0791-.0058.2239-.0016.4481-.011.6718-.0014.0338-.0578.0652-.0887.0977-.0207-.03-.0589-.0593-.0593-.0893-.0049-.3557-.01-.7116-.0018-1.0671.0027-.1265.0927-.1708.1838-.0857a2.0718,2.0718,0,0,1,.2867.3636c.1016.151.1893.1635.2894.0147a1.3761,1.3761,0,0,0,.1447-.28c.0553-.1466.1809-.1373.2888-.1234.0317.0041.0632.1258.0682.1955.01.1443.0032.29.0032.4349Z" transform="translate(-1.5128 -1.7744)"></path>
                                    <path fill="#ea69a3" d="M95.609,2.561c0-.0659-.0041-.1322.0008-.1977.0149-.1985.0069-.3723-.2646-.3765-.0384-.0006-.0754-.0866-.1131-.1331.0522-.0261.1039-.0734.1568-.0748.25-.0067.5011-.0067.751.006.0457.0024.0878.0748.1315.1149a.6161.6161,0,0,1-.1122.0978.2421.2421,0,0,1-.1167.0122c-.1594-.0065-.2021.0833-.2.2242.0043.2373.01.4752-.004.7118-.0032.0543-.0819.1043-.1259.1564-.034-.0486-.0917-.0945-.0972-.1461a3.6888,3.6888,0,0,1-.0047-.3951Z" transform="translate(-1.5128 -1.7744)"></path>
                                </svg>
                            </div>
                            <h1 class="text-center display-3 font-weight-bold d-flex justify-content-center align-items-center">
                                私密超頻收
                                <span class="title-em d-inline-block font-weight-bold mx-3">陰</span>
                                機
                            </h1>
                            <div class="version position-absolute">
                                <h2 class="font-weight-bold">4.0</h2>
                            </div>
                        </div>

                        <div class="title position-relative d-none d-sm-block d-md-none">
                            <div class="logo logo-md position-absolute">
                                <svg id="Isolation_Mode" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96.3158 36.6237">
                                    <path fill="#ea69a3" d="M56.7612,12.3776q0-4.7341.0009-9.4683c0-.1648-.0452-.3768.0382-.479.065-.0795.2974-.0255.4555-.0255,2.6675,0,5.335-.0033,8.0025.0053a10.4868,10.4868,0,0,1,1.6152.0854,7.6057,7.6057,0,0,1,3.6932,1.4866A5.1255,5.1255,0,0,1,72.4436,7.1a6.9862,6.9862,0,0,1,.0373,2.24,4.917,4.917,0,0,1-.9941,2.4509,5.7531,5.7531,0,0,1-1.993,1.6425,10.61,10.61,0,0,1-2.1123.7469c-.2057.05-.21.07-.0852.2379q.599.8094,1.208,1.6113c.412.5454.8284,1.0876,1.24,1.6333q1.0583,1.4022,2.1125,2.8075c.4257.5687.8457,1.1418,1.2655,1.7149a.8322.8322,0,0,1,.0738.1679.8968.8968,0,0,1-.1528.0263c-.7659.0011-1.5318-.0047-2.2977.0023a.48.48,0,0,1-.423-.2328q-2.4162-3.2431-4.8419-6.4794c-.2127-.2845-.4258-.5689-.6319-.8582a.52.52,0,0,0-.4821-.2025c-1.6771.0063-3.3542.0034-5.0313.0055-.3363.0005-.34.0051-.34.3562q-.0014,3.4467,0,6.8933c0,.066.001.1321,0,.1981-.0059.3146-.0061.3187-.3006.32-.5941.0026-1.1884.0009-1.7826,0-.09,0-.1673.0144-.167-.1393q.01-4.932.0049-9.8639ZM58.9951,8.59h.0008q0,1.782,0,3.5641c0,.1056-.0065.2119.004.3165.0038.038.0464.1015.0745.1033.3268.0215.6541.0444.9814.0457,1.3332.0053,2.6665,0,4,.005a13.6262,13.6262,0,0,0,2.3586-.1484,5.8831,5.8831,0,0,0,2.4281-.9771,3.3137,3.3137,0,0,0,1.0226-1.24A4.1591,4.1591,0,0,0,70.11,7.2974,3.3506,3.3506,0,0,0,68.1167,5.043a7,7,0,0,0-2.959-.5645c-1.9138-.021-3.8281-.005-5.7421-.0043-.0792,0-.1586.0033-.2376,0-.14-.0064-.1862.0686-.1826.1953.0023.0792,0,.1584,0,.2376Q58.9951,6.7483,58.9951,8.59Z" transform="translate(-1.5128 -1.7744)"></path>
                                    <path fill="#ea69a3" d="M95.1145,14.5369q-1.1886,0-2.3772.0008c-.0778,0-.1556.0122-.2334.0187.0248.0675.0457.1367.075.2022q.6351,1.4192,1.2723,2.8373.8649,1.9271,1.7309,3.8537c.1085.2405.2336.4734.3451.7126.08.1719.058.2137-.1251.2152-.4887.0041-.9774.0009-1.466-.0022-.2373-.0016-.5309.0742-.6965-.0372-.18-.1209-.2505-.4144-.3514-.64-.6331-1.418-1.26-2.8387-1.8917-4.2574-.3945-.8865-.8014-1.7676-1.1832-2.6594a.3478.3478,0,0,0-.3833-.246c-1.0565.0073-2.1131.0031-3.17.0032q-2.3971,0-4.7942.0011c-.3862,0-.3746.0069-.5363.3753-.3123.7115-.643,1.4149-.9591,2.1248-.5832,1.31-1.16,2.6218-1.7423,3.9317-.1762.3962-.3631.7876-.5417,1.1827a.3154.3154,0,0,1-.3213.2315c-.6206-.0107-1.2414-.0015-1.8621-.0066-.1859-.0015-.2051-.0374-.1279-.2122q.3434-.7773.6939-1.5514.8667-1.9248,1.7356-3.8487c.4457-.9827.9-1.9617,1.3465-2.9438q.7841-1.7227,1.5625-3.448.6837-1.512,1.3651-3.025c.3146-.695.6344-1.3878.9486-2.0831.4166-.9217.8345-1.8429,1.2409-2.7691a.3546.3546,0,0,1,.3824-.2545c.5146.0142,1.03.0179,1.5448.0021a.3927.3927,0,0,1,.427.2641c.2591.59.529,1.175.7937,1.7624q.6964,1.5459,1.3916,3.0922Q89.8734,8.84,90.54,10.3155c.3135.6934.6367,1.3825.9388,2.0807a.5228.5228,0,0,0,.5719.3142c1.5452-.0046,3.09-.0021,4.6356-.002.3038,0,.6077.0061.9112-.002.1724-.0047.2332.0673.2309.2339-.0064.449.0006.8981-.0063,1.3471-.0036.233-.0144.245-.2513.2464-.8188.0045-1.6376.0017-2.4565.0017Zm-6.3639-1.8232c.1049-.0081.21-.0167.3147-.0243.13-.0095.22-.0181.1329-.2-.2435-.5109-.4675-1.0312-.6958-1.5493-.4667-1.0586-.929-2.1191-1.3973-3.177-.346-.7817-.6982-1.5608-1.0484-2.3407-.0748-.1666-.1531-.3317-.23-.4975-.0857.17-.1772.3369-.2561.51-.5616,1.23-1.1259,2.4583-1.6788,3.692-.49,1.0931-.9666,2.1921-1.4483,3.2888-.1119.2549-.1093.2552.1925.2277a2.2631,2.2631,0,0,1,.2769-.0038c.6838.0215,1.3675.0589,2.0514.0639Q86.8572,12.7174,88.7506,12.7137Z" transform="translate(-1.5128 -1.7744)"></path>
                                    <path fill="#ea69a3" d="M15.8816,8.91q0-3.0506,0-6.1012c0-.41.0013-.41.41-.41.5414.0005,1.0829-.0009,1.6243.0035.2549.0021.2549.0074.2624.27.0007.0264,0,.0528,0,.0792,0,3.843.0071,7.686-.0056,11.5289a9.8087,9.8087,0,0,1-.9311,4.2444,6.8854,6.8854,0,0,1-2.6879,2.98A8.0682,8.0682,0,0,1,11.56,22.5589a10.8811,10.8811,0,0,1-2.3224.1284,8.4552,8.4552,0,0,1-4.4235-1.3934,7.2981,7.2981,0,0,1-2.9048-4.2011A11.716,11.716,0,0,1,1.5194,13.89q.01-5.4474.0026-10.895c0-.0924-.0016-.1849,0-.2773.0066-.31.0071-.3151.302-.3175q.8517-.0069,1.7036-.0026c.276.0018.2849.0164.2947.2927.0033.0923.0006.1849.0006.2773q0,5.4674-.003,10.9346a8.9527,8.9527,0,0,0,.3329,2.6141,5.2447,5.2447,0,0,0,3.8342,3.8327,8.5488,8.5488,0,0,0,2.5114.201,5.6963,5.6963,0,0,0,2.8431-.85,4.9927,4.9927,0,0,0,2.0394-2.5449A7.3509,7.3509,0,0,0,15.88,14.417c-.0074-1.8357-.0021-3.6713-.0021-5.507Z" transform="translate(-1.5128 -1.7744)"></path>
                                    <path fill="#ea69a3" d="M34.1855,32.5633c0,1.4524-.0031,2.9049.0013,4.3574a1.3251,1.3251,0,0,1-.9377,1.3506,1.2829,1.2829,0,0,1-1.54-1.1231c.0205-1.1617.005-2.3239.0049-3.4859,0-.66.0017-1.3205-.0008-1.9806a.414.414,0,0,0-.0455-.1277.41.41,0,0,0-.0846.0744c-.67,1.0417-1.3275,2.0912-2.0117,3.1232a1.2672,1.2672,0,0,1-.8966.65,1.1247,1.1247,0,0,1-1.151-.5193c-.6354-.9376-1.2308-1.9024-1.8466-2.8534-.0914-.1411-.2064-.2668-.31-.4a4.43,4.43,0,0,0-.0512.478c-.0036,1.6506,0,3.3011-.0028,4.9517a1.1217,1.1217,0,0,1-.8876,1.195,1.1965,1.1965,0,0,1-1.4786-.78,2.2446,2.2446,0,0,1-.0909-.6217q-.0085-4.2981-.0012-8.5961a1.366,1.366,0,0,1,.5768-1.194.8137.8137,0,0,1,.5135-.1748,1.1174,1.1174,0,0,0,.1979-.0048,1.47,1.47,0,0,1,1.5484.8471c.8958,1.4905,1.8163,2.9662,2.7266,4.4481.1122.1824.1153.183.217.0176q1.4077-2.2888,2.818-4.576a2.2577,2.2577,0,0,1,.3417-.4315.9624.9624,0,0,1,.7467-.3023,3.92,3.92,0,0,1,.8222.0558,1.1978,1.1978,0,0,1,.8169,1.2252c.0055,1.1488.0019,2.2976.002,3.4463q0,.4754,0,.9508Z" transform="translate(-1.5128 -1.7744)"></path>
                                    <path fill="#ea69a3" d="M48.137,32.5857c0,1.4918-.0085,2.9836.0048,4.4752a1.219,1.219,0,0,1-.826,1.1723,1.2529,1.2529,0,0,1-1.6479-1.1419c-.01-.2107-.0016-.4223-.0016-.6335V31.8637c0-.0792.0016-.1585-.0012-.2376a.2864.2864,0,0,0-.0218-.0713.26.26,0,0,0-.0578.0484c-.3438.53-.6852,1.0622-1.03,1.5922s-.6775,1.0675-1.0428,1.5825a1.2962,1.2962,0,0,1-.87.6288.9928.9928,0,0,1-.7461-.1525,1.9636,1.9636,0,0,1-.4922-.5068c-.6114-.9209-1.204-1.8543-1.8063-2.7813-.073-.1124-.1642-.213-.247-.3191-.006.1309-.0173.2618-.0174.3927q-.002,2.4357.0008,4.8714a1.496,1.496,0,0,1-.258.8942,1.2125,1.2125,0,0,1-1.37.4375,1.2708,1.2708,0,0,1-.8434-1.1649q.0183-4.495.0024-8.99a1.3094,1.3094,0,0,1,1.2-1.2212,1.6508,1.6508,0,0,1,1.6619.9448c.56.9837,1.1821,1.9319,1.7762,2.8962q.4664.757.9265,1.518c.0747.1232.1157.1.1835-.0117q1.1934-1.9563,2.3951-3.9075c.18-.2916.3755-.5731.5543-.8652a1.087,1.087,0,0,1,1.0537-.5546c.079.0041.1584-.0007.2376,0A1.1612,1.1612,0,0,1,48.1372,28.15q.0045,2.2178.0009,4.4357Z" transform="translate(-1.5128 -1.7744)"></path>
                                    <path fill="#ea69a3" d="M45.9045,13.4508q0,4.2381,0,8.4763c0,.47.0471.4539-.4471.4524q-.6931-.0023-1.3863.0013c-.5031.0021-.4588.03-.4623-.48-.0021-.3036,0-.6073,0-.911q0-8.0207,0-16.0415c0-.52.0558-.4728-.46-.4731q-2.931-.0021-5.8621,0c-.417,0-.4164.0337-.4132-.4049.0034-.4753,0-.9506.0043-1.4258.002-.2326.0067-.2328.2471-.2416.04-.0014.0792,0,.1188,0l15.0512-.0024c.04,0,.0794.003.1188,0,.1644-.011.25.0571.2379.2294-.0036.0525,0,.1056,0,.1584,0,.4357.0012.8714-.001,1.307-.0019.3754-.0037.3759-.3631.3779-.1716.001-.3433-.0008-.5149-.0007q-2.6538,0-5.3075.0007c-.0132,0-.0264,0-.04,0-.5725.0043-.5187-.0559-.5189.5Q45.9032,9.2127,45.9045,13.4508Z" transform="translate(-1.5128 -1.7744)"></path>
                                    <path fill="#ea69a3" d="M23.4837,12.3791q0-4.7535,0-9.5071c0-.5015-.0364-.4741.47-.4718.5282.0023,1.0564-.0018,1.5845.0031.2363.0021.2378.01.2464.2457.0023.0659,0,.132,0,.198q0,8.4773-.0006,16.9543c0,.4724-.0468.4432.454.4433q5.1695.0011,10.3389.0009c.4816,0,.46-.0408.46.4405-.0007.4753.0032.9507-.0022,1.426-.0029.2575-.01.2583-.2721.2677-.0923.0032-.1848.0006-.2773.0007l-12.5572.0031h-.04c-.4013-.001-.4041-.001-.4042-.4182Q23.4831,17.1723,23.4837,12.3791Z" transform="translate(-1.5128 -1.7744)"></path>
                                    <path fill="#ea69a3" d="M12.0668,32.5354c0-1.4258-.0028-2.8515.0016-4.2773A1.253,1.253,0,0,1,12.9072,27a2.1745,2.1745,0,0,1,.62-.0975c1.9538-.0072,3.9077-.0027,5.8615-.0064a1.557,1.557,0,0,1,.7926.2068,1.0892,1.0892,0,0,1-.5176,2.0225c-1.5182.0043-3.0364.0017-4.5545.0017-.0924,0-.1853.0059-.2772-.0013-.1717-.0135-.234.0635-.2325.2314.0051.5808-.0006,1.1617.0031,1.7426.002.3.007.3059.3093.3067q1.8217.0047,3.6436.0032c.1188,0,.2381-.0056.3563.0035a1.1254,1.1254,0,0,1,1.0005.7242,1.101,1.101,0,0,1-.9868,1.4957c-.1443.012-.29.004-.4355.0041q-1.7822.0017-3.5644.0039c-.3191.0009-.3226.0066-.324.3331q-.0038.8516.001,1.703c.002.3161.0061.32.3355.32q2.1387.0025,4.2773.0012c.1584,0,.3169-.0012.4753.0026a1.1015,1.1015,0,0,1,1.1043,1.0622,1.147,1.147,0,0,1-1.0673,1.1591c-.2364.0166-.475.0019-.7126.0019-1.7822,0-3.5645.0056-5.3467-.0038a2.5583,2.5583,0,0,1-.8133-.1168,1.186,1.186,0,0,1-.782-1.1726c-.025-.4872-.0064-.9767-.0066-1.4651Q12.0664,34.0009,12.0668,32.5354Z" transform="translate(-1.5128 -1.7744)"></path>
                                    <path fill="#ea69a3" d="M50.8332,32.7946c0-1.1353-.0061-2.2707.0028-3.406.004-.5.0316-.9992.0616-1.498a1.1357,1.1357,0,0,1,.7927-.8991,2.2177,2.2177,0,0,1,.5829-.0868q2.9109-.0091,5.8218.0009a2.2059,2.2059,0,0,1,.62.0981A1.11,1.11,0,0,1,59.47,28.053a1.0843,1.0843,0,0,1-.77,1.04,1.6112,1.6112,0,0,1-.4316.0294q-2.2575.0027-4.5149.0012c-.46,0-.4687.0014-.46.4564.0085.4469.0372.8934.0559,1.34a1.126,1.126,0,0,1-.0046.158c-.0328.33-.0333.3357.3034.336q1.8812.0018,3.7623-.0053a1.4514,1.4514,0,0,1,.727.1446,1.0821,1.0821,0,0,1,.5811,1.1866,1.1131,1.1131,0,0,1-1.0657.8938c-.1575.0116-.3167-.0011-.4751-.0011q-1.703,0-3.4059.0008a2.7148,2.7148,0,0,0-.3554.0158.1409.1409,0,0,0-.0979.0919c-.0152.2994-.0284.5995-.0254.8991.004.4062.0193.8123.04,1.2179a.1779.1779,0,0,0,.1067.1308,1.9852,1.9852,0,0,0,.3548.0154c1.5314.001,3.0628.0043,4.5941-.0007a1.1012,1.1012,0,0,1,.506,2.1.9131.9131,0,0,1-.3844.0533c-1.5181.004-3.0363-.0016-4.5544.0069-.5261.0029-1.0518.0436-1.5779.0559a1.8842,1.8842,0,0,1-.9169-.1858,1.1146,1.1146,0,0,1-.5467-.6956,2.226,2.226,0,0,1-.0788-.6627C50.83,35.3821,50.8331,34.0883,50.8332,32.7946Z" transform="translate(-1.5128 -1.7744)"></path>
                                    <path fill="#ea69a3" d="M91.5415,26.7091a4.976,4.976,0,0,1,3.522,1.4363,5.618,5.618,0,0,1,1.21,2.0026,8.1645,8.1645,0,0,1,.4313,2.5411,6.4506,6.4506,0,0,1-.0936.899,6.077,6.077,0,0,1-.3839,1.5192,5.4542,5.4542,0,0,1-.503,1.0285,4.6447,4.6447,0,0,1-2.437,1.9714,4.2743,4.2743,0,0,1-2.17.2249,7.1137,7.1137,0,0,1-.8874-.1587,4.7958,4.7958,0,0,1-2.4608-1.8125,5.4242,5.4242,0,0,1-.837-1.8128,4.2879,4.2879,0,0,1-.2136-1.2766c.0207-.4309-.0113-.8644.0092-1.2953A5.7,5.7,0,0,1,88.256,28.228a4.5849,4.5849,0,0,1,2.0868-1.2986A10.7906,10.7906,0,0,1,91.5415,26.7091ZM94.2,32.4606c-.0441-.2515-.1136-.6373-.1789-1.0238a3.1843,3.1843,0,0,0-.9846-1.9219,2.0313,2.0313,0,0,0-2.9276.1613,3.5792,3.5792,0,0,0-.6743,1.2434,11.2982,11.2982,0,0,0-.2438,1.5531.5192.5192,0,0,0,.0137.1566c.0191.1694.0483.3383.0562.5082a3.559,3.559,0,0,0,.9659,2.3962,1.9133,1.9133,0,0,0,1.483.6273,2.0839,2.0839,0,0,0,1.7082-.9106A5.2283,5.2283,0,0,0,94.2,32.4606Z" transform="translate(-1.5128 -1.7744)"></path>
                                    <path fill="#ea69a3" d="M76.0056,32.9379a9.0367,9.0367,0,0,1,.43-2.9122,4.5565,4.5565,0,0,1,2.335-2.8034,5.0789,5.0789,0,0,1,2.9936-.4244,6.3027,6.3027,0,0,1,1.7649.5291,1.3257,1.3257,0,0,1,.7966,1.0991,1.0335,1.0335,0,0,1-.375.8785,1.1268,1.1268,0,0,1-1.3558.1616,4.5635,4.5635,0,0,0-1.3721-.4628,2.1276,2.1276,0,0,0-2.2356,1.1732,5.6246,5.6246,0,0,0-.3724,1.2338.4658.4658,0,0,0,.019.1334.714.714,0,0,0,.1078-.0335c.2354-.1158.4633-.25.7057-.3476a3.7607,3.7607,0,0,1,1.7716-.295,4.1766,4.1766,0,0,1,2.7145,1.0932,3.033,3.033,0,0,1,.8973,1.8407,4.7607,4.7607,0,0,1,.066.7433,3.7225,3.7225,0,0,1-2.3321,3.4474,5.3389,5.3389,0,0,1-1.3658.3424,5.5623,5.5623,0,0,1-1.6465-.0335,3.9167,3.9167,0,0,1-2.2967-1.2,4.66,4.66,0,0,1-1.1379-2.5129A15.6572,15.6572,0,0,1,76.0056,32.9379Zm4.4936,3.3074c.0792,0,.1585.0006.2376,0a1.6534,1.6534,0,0,0,1.6779-1.3792,1.4984,1.4984,0,0,0-.5628-1.5067A2.1244,2.1244,0,0,0,81.0731,33a2.16,2.16,0,0,0-1.7216.18,1.5992,1.5992,0,0,0-.2775,2.6079A1.8683,1.8683,0,0,0,80.4992,36.2453Z" transform="translate(-1.5128 -1.7744)"></path>
                                    <path fill="#ea69a3" d="M70.0716,26.9024c.7792,0,1.5584-.0065,2.3375.0034a4.8645,4.8645,0,0,1,.8227.0763,1.0739,1.0739,0,0,1,.8518,1.362,1.3967,1.3967,0,0,1-.3383.6528c-.7521.85-1.6287,1.5716-2.4167,2.3837-.0344.0355-.0661.0737-.099.1106a.6244.6244,0,0,0,.1586.0743,4.0407,4.0407,0,0,1,1.637.6521,2.47,2.47,0,0,1,.9923,1.3,3.664,3.664,0,0,1-.442,3.2813,3.39,3.39,0,0,1-1.9515,1.38,5.9838,5.9838,0,0,1-3.3449-.0412,4.8818,4.8818,0,0,1-1.8578-1.0135,1.1531,1.1531,0,0,1-.4475-.9731,1.2,1.2,0,0,1,.9-1.0852,1.2866,1.2866,0,0,1,1.23.3618,3.0594,3.0594,0,0,0,2.2275.73,2.1105,2.1105,0,0,0,.7845-.2352,1.31,1.31,0,0,0-.06-2.2819,2.0493,2.0493,0,0,0-.8672-.24,6.9185,6.9185,0,0,0-.8977-.0478,1.1231,1.1231,0,0,1-1.1518-.8357.9976.9976,0,0,1,.1739-.86,3.6037,3.6037,0,0,1,.4563-.5433c.5737-.5839,1.1555-1.16,1.7337-1.7393a1.5584,1.5584,0,0,0,.1613-.1741c.0683-.0934.0237-.1454-.0785-.153-.0919-.0068-.1847-.0035-.2771-.0035-.9641-.0005-1.9281.0014-2.8922-.0019A1.0452,1.0452,0,0,1,67,27.0144a1.8491,1.8491,0,0,1,.576-.107C68.4074,26.8959,69.24,26.9025,70.0716,26.9024Z" transform="translate(-1.5128 -1.7744)"></path>
                                    <path fill="#ea69a3" d="M1.5216,32.6065c0-1.4656.0026-2.9313-.0012-4.397a1.2089,1.2089,0,0,1,.5154-1.0566,1.5146,1.5146,0,0,1,.8956-.2557c1.3072.0032,2.6145.0014,3.9217.0013.713,0,1.4261-.0061,2.1391.0018a1.1918,1.1918,0,0,1,1.1916.86,1.1229,1.1229,0,0,1-1.008,1.4353c-.1179.0109-.2375.0048-.3563.0048q-2.1589.0008-4.3179.001c-.0924,0-.1853.0062-.2772-.0009-.172-.0133-.231.0629-.2292.2317.0064.6073-.0008,1.2148.0048,1.8222.0027.2917.0241.3132.3213.3141,1.1752.0033,2.35.0011,3.5256.0008.1584,0,.3169-.0046.4753-.0011a1.1043,1.1043,0,0,1,1.18,1.1247A1.13,1.13,0,0,1,8.4,33.8666a4.0307,4.0307,0,0,1-.5146.0044q-1.7428,0-3.4859-.0009c-.4459-.0006-.4009-.005-.4015.4152-.0015.9243-.0247,1.8494.0065,2.7727a1.2078,1.2078,0,0,1-1.24,1.2523A1.2872,1.2872,0,0,1,1.513,37.0431C1.5386,35.5646,1.5214,34.0854,1.5216,32.6065Z" transform="translate(-1.5128 -1.7744)"></path>
                                    <path fill="#ea69a3" d="M97.82,2.441c0,.1844.0052.369-.0043.5529-.002.0377-.0731.1082-.0882.1023-.05-.02-.1252-.0652-.128-.1051-.0156-.2231-.0112-.4476-.0182-.6715-.0009-.0287-.0284-.0566-.0435-.0849a.7268.7268,0,0,1-.0526.0541c-.1366.114-.1454.4073-.3508.3708-.1185-.021-.1986-.2539-.2983-.39-.0141-.0192-.0554-.0443-.06-.04-.0235.0218-.0548.0514-.0555.0791-.0058.2239-.0016.4481-.011.6718-.0014.0338-.0578.0652-.0887.0977-.0207-.03-.0589-.0593-.0593-.0893-.0049-.3557-.01-.7116-.0018-1.0671.0027-.1265.0927-.1708.1838-.0857a2.0718,2.0718,0,0,1,.2867.3636c.1016.151.1893.1635.2894.0147a1.3761,1.3761,0,0,0,.1447-.28c.0553-.1466.1809-.1373.2888-.1234.0317.0041.0632.1258.0682.1955.01.1443.0032.29.0032.4349Z" transform="translate(-1.5128 -1.7744)"></path>
                                    <path fill="#ea69a3" d="M95.609,2.561c0-.0659-.0041-.1322.0008-.1977.0149-.1985.0069-.3723-.2646-.3765-.0384-.0006-.0754-.0866-.1131-.1331.0522-.0261.1039-.0734.1568-.0748.25-.0067.5011-.0067.751.006.0457.0024.0878.0748.1315.1149a.6161.6161,0,0,1-.1122.0978.2421.2421,0,0,1-.1167.0122c-.1594-.0065-.2021.0833-.2.2242.0043.2373.01.4752-.004.7118-.0032.0543-.0819.1043-.1259.1564-.034-.0486-.0917-.0945-.0972-.1461a3.6888,3.6888,0,0,1-.0047-.3951Z" transform="translate(-1.5128 -1.7744)"></path>
                                </svg>
                            </div>
                            <h1 class="text-center display-3 font-weight-bold mb-3">
                                私密超頻
                            </h1>
                            <h1 class="text-center display-3 font-weight-bold d-flex justify-content-center align-items-center">
                                收
                                <span class="title-em d-inline-block font-weight-bold mx-3">陰</span>
                                機
                            </h1>
                            <div class="version version-md position-absolute">
                                <h2 class="font-weight-bold">4.0</h2>
                            </div>
                        </div>

                        <div class="title position-relative d-block d-sm-none">
                            <div class="logo logo-md position-absolute">
                                <svg id="Isolation_Mode" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96.3158 36.6237">
                                    <path d="M56.7612,12.3776q0-4.7341.0009-9.4683c0-.1648-.0452-.3768.0382-.479.065-.0795.2974-.0255.4555-.0255,2.6675,0,5.335-.0033,8.0025.0053a10.4868,10.4868,0,0,1,1.6152.0854,7.6057,7.6057,0,0,1,3.6932,1.4866A5.1255,5.1255,0,0,1,72.4436,7.1a6.9862,6.9862,0,0,1,.0373,2.24,4.917,4.917,0,0,1-.9941,2.4509,5.7531,5.7531,0,0,1-1.993,1.6425,10.61,10.61,0,0,1-2.1123.7469c-.2057.05-.21.07-.0852.2379q.599.8094,1.208,1.6113c.412.5454.8284,1.0876,1.24,1.6333q1.0583,1.4022,2.1125,2.8075c.4257.5687.8457,1.1418,1.2655,1.7149a.8322.8322,0,0,1,.0738.1679.8968.8968,0,0,1-.1528.0263c-.7659.0011-1.5318-.0047-2.2977.0023a.48.48,0,0,1-.423-.2328q-2.4162-3.2431-4.8419-6.4794c-.2127-.2845-.4258-.5689-.6319-.8582a.52.52,0,0,0-.4821-.2025c-1.6771.0063-3.3542.0034-5.0313.0055-.3363.0005-.34.0051-.34.3562q-.0014,3.4467,0,6.8933c0,.066.001.1321,0,.1981-.0059.3146-.0061.3187-.3006.32-.5941.0026-1.1884.0009-1.7826,0-.09,0-.1673.0144-.167-.1393q.01-4.932.0049-9.8639ZM58.9951,8.59h.0008q0,1.782,0,3.5641c0,.1056-.0065.2119.004.3165.0038.038.0464.1015.0745.1033.3268.0215.6541.0444.9814.0457,1.3332.0053,2.6665,0,4,.005a13.6262,13.6262,0,0,0,2.3586-.1484,5.8831,5.8831,0,0,0,2.4281-.9771,3.3137,3.3137,0,0,0,1.0226-1.24A4.1591,4.1591,0,0,0,70.11,7.2974,3.3506,3.3506,0,0,0,68.1167,5.043a7,7,0,0,0-2.959-.5645c-1.9138-.021-3.8281-.005-5.7421-.0043-.0792,0-.1586.0033-.2376,0-.14-.0064-.1862.0686-.1826.1953.0023.0792,0,.1584,0,.2376Q58.9951,6.7483,58.9951,8.59Z" transform="translate(-1.5128 -1.7744)"></path>
                                    <path d="M95.1145,14.5369q-1.1886,0-2.3772.0008c-.0778,0-.1556.0122-.2334.0187.0248.0675.0457.1367.075.2022q.6351,1.4192,1.2723,2.8373.8649,1.9271,1.7309,3.8537c.1085.2405.2336.4734.3451.7126.08.1719.058.2137-.1251.2152-.4887.0041-.9774.0009-1.466-.0022-.2373-.0016-.5309.0742-.6965-.0372-.18-.1209-.2505-.4144-.3514-.64-.6331-1.418-1.26-2.8387-1.8917-4.2574-.3945-.8865-.8014-1.7676-1.1832-2.6594a.3478.3478,0,0,0-.3833-.246c-1.0565.0073-2.1131.0031-3.17.0032q-2.3971,0-4.7942.0011c-.3862,0-.3746.0069-.5363.3753-.3123.7115-.643,1.4149-.9591,2.1248-.5832,1.31-1.16,2.6218-1.7423,3.9317-.1762.3962-.3631.7876-.5417,1.1827a.3154.3154,0,0,1-.3213.2315c-.6206-.0107-1.2414-.0015-1.8621-.0066-.1859-.0015-.2051-.0374-.1279-.2122q.3434-.7773.6939-1.5514.8667-1.9248,1.7356-3.8487c.4457-.9827.9-1.9617,1.3465-2.9438q.7841-1.7227,1.5625-3.448.6837-1.512,1.3651-3.025c.3146-.695.6344-1.3878.9486-2.0831.4166-.9217.8345-1.8429,1.2409-2.7691a.3546.3546,0,0,1,.3824-.2545c.5146.0142,1.03.0179,1.5448.0021a.3927.3927,0,0,1,.427.2641c.2591.59.529,1.175.7937,1.7624q.6964,1.5459,1.3916,3.0922Q89.8734,8.84,90.54,10.3155c.3135.6934.6367,1.3825.9388,2.0807a.5228.5228,0,0,0,.5719.3142c1.5452-.0046,3.09-.0021,4.6356-.002.3038,0,.6077.0061.9112-.002.1724-.0047.2332.0673.2309.2339-.0064.449.0006.8981-.0063,1.3471-.0036.233-.0144.245-.2513.2464-.8188.0045-1.6376.0017-2.4565.0017Zm-6.3639-1.8232c.1049-.0081.21-.0167.3147-.0243.13-.0095.22-.0181.1329-.2-.2435-.5109-.4675-1.0312-.6958-1.5493-.4667-1.0586-.929-2.1191-1.3973-3.177-.346-.7817-.6982-1.5608-1.0484-2.3407-.0748-.1666-.1531-.3317-.23-.4975-.0857.17-.1772.3369-.2561.51-.5616,1.23-1.1259,2.4583-1.6788,3.692-.49,1.0931-.9666,2.1921-1.4483,3.2888-.1119.2549-.1093.2552.1925.2277a2.2631,2.2631,0,0,1,.2769-.0038c.6838.0215,1.3675.0589,2.0514.0639Q86.8572,12.7174,88.7506,12.7137Z" transform="translate(-1.5128 -1.7744)"></path>
                                    <path d="M15.8816,8.91q0-3.0506,0-6.1012c0-.41.0013-.41.41-.41.5414.0005,1.0829-.0009,1.6243.0035.2549.0021.2549.0074.2624.27.0007.0264,0,.0528,0,.0792,0,3.843.0071,7.686-.0056,11.5289a9.8087,9.8087,0,0,1-.9311,4.2444,6.8854,6.8854,0,0,1-2.6879,2.98A8.0682,8.0682,0,0,1,11.56,22.5589a10.8811,10.8811,0,0,1-2.3224.1284,8.4552,8.4552,0,0,1-4.4235-1.3934,7.2981,7.2981,0,0,1-2.9048-4.2011A11.716,11.716,0,0,1,1.5194,13.89q.01-5.4474.0026-10.895c0-.0924-.0016-.1849,0-.2773.0066-.31.0071-.3151.302-.3175q.8517-.0069,1.7036-.0026c.276.0018.2849.0164.2947.2927.0033.0923.0006.1849.0006.2773q0,5.4674-.003,10.9346a8.9527,8.9527,0,0,0,.3329,2.6141,5.2447,5.2447,0,0,0,3.8342,3.8327,8.5488,8.5488,0,0,0,2.5114.201,5.6963,5.6963,0,0,0,2.8431-.85,4.9927,4.9927,0,0,0,2.0394-2.5449A7.3509,7.3509,0,0,0,15.88,14.417c-.0074-1.8357-.0021-3.6713-.0021-5.507Z" transform="translate(-1.5128 -1.7744)"></path>
                                    <path d="M34.1855,32.5633c0,1.4524-.0031,2.9049.0013,4.3574a1.3251,1.3251,0,0,1-.9377,1.3506,1.2829,1.2829,0,0,1-1.54-1.1231c.0205-1.1617.005-2.3239.0049-3.4859,0-.66.0017-1.3205-.0008-1.9806a.414.414,0,0,0-.0455-.1277.41.41,0,0,0-.0846.0744c-.67,1.0417-1.3275,2.0912-2.0117,3.1232a1.2672,1.2672,0,0,1-.8966.65,1.1247,1.1247,0,0,1-1.151-.5193c-.6354-.9376-1.2308-1.9024-1.8466-2.8534-.0914-.1411-.2064-.2668-.31-.4a4.43,4.43,0,0,0-.0512.478c-.0036,1.6506,0,3.3011-.0028,4.9517a1.1217,1.1217,0,0,1-.8876,1.195,1.1965,1.1965,0,0,1-1.4786-.78,2.2446,2.2446,0,0,1-.0909-.6217q-.0085-4.2981-.0012-8.5961a1.366,1.366,0,0,1,.5768-1.194.8137.8137,0,0,1,.5135-.1748,1.1174,1.1174,0,0,0,.1979-.0048,1.47,1.47,0,0,1,1.5484.8471c.8958,1.4905,1.8163,2.9662,2.7266,4.4481.1122.1824.1153.183.217.0176q1.4077-2.2888,2.818-4.576a2.2577,2.2577,0,0,1,.3417-.4315.9624.9624,0,0,1,.7467-.3023,3.92,3.92,0,0,1,.8222.0558,1.1978,1.1978,0,0,1,.8169,1.2252c.0055,1.1488.0019,2.2976.002,3.4463q0,.4754,0,.9508Z" transform="translate(-1.5128 -1.7744)"></path>
                                    <path d="M48.137,32.5857c0,1.4918-.0085,2.9836.0048,4.4752a1.219,1.219,0,0,1-.826,1.1723,1.2529,1.2529,0,0,1-1.6479-1.1419c-.01-.2107-.0016-.4223-.0016-.6335V31.8637c0-.0792.0016-.1585-.0012-.2376a.2864.2864,0,0,0-.0218-.0713.26.26,0,0,0-.0578.0484c-.3438.53-.6852,1.0622-1.03,1.5922s-.6775,1.0675-1.0428,1.5825a1.2962,1.2962,0,0,1-.87.6288.9928.9928,0,0,1-.7461-.1525,1.9636,1.9636,0,0,1-.4922-.5068c-.6114-.9209-1.204-1.8543-1.8063-2.7813-.073-.1124-.1642-.213-.247-.3191-.006.1309-.0173.2618-.0174.3927q-.002,2.4357.0008,4.8714a1.496,1.496,0,0,1-.258.8942,1.2125,1.2125,0,0,1-1.37.4375,1.2708,1.2708,0,0,1-.8434-1.1649q.0183-4.495.0024-8.99a1.3094,1.3094,0,0,1,1.2-1.2212,1.6508,1.6508,0,0,1,1.6619.9448c.56.9837,1.1821,1.9319,1.7762,2.8962q.4664.757.9265,1.518c.0747.1232.1157.1.1835-.0117q1.1934-1.9563,2.3951-3.9075c.18-.2916.3755-.5731.5543-.8652a1.087,1.087,0,0,1,1.0537-.5546c.079.0041.1584-.0007.2376,0A1.1612,1.1612,0,0,1,48.1372,28.15q.0045,2.2178.0009,4.4357Z" transform="translate(-1.5128 -1.7744)"></path>
                                    <path d="M45.9045,13.4508q0,4.2381,0,8.4763c0,.47.0471.4539-.4471.4524q-.6931-.0023-1.3863.0013c-.5031.0021-.4588.03-.4623-.48-.0021-.3036,0-.6073,0-.911q0-8.0207,0-16.0415c0-.52.0558-.4728-.46-.4731q-2.931-.0021-5.8621,0c-.417,0-.4164.0337-.4132-.4049.0034-.4753,0-.9506.0043-1.4258.002-.2326.0067-.2328.2471-.2416.04-.0014.0792,0,.1188,0l15.0512-.0024c.04,0,.0794.003.1188,0,.1644-.011.25.0571.2379.2294-.0036.0525,0,.1056,0,.1584,0,.4357.0012.8714-.001,1.307-.0019.3754-.0037.3759-.3631.3779-.1716.001-.3433-.0008-.5149-.0007q-2.6538,0-5.3075.0007c-.0132,0-.0264,0-.04,0-.5725.0043-.5187-.0559-.5189.5Q45.9032,9.2127,45.9045,13.4508Z" transform="translate(-1.5128 -1.7744)"></path>
                                    <path d="M23.4837,12.3791q0-4.7535,0-9.5071c0-.5015-.0364-.4741.47-.4718.5282.0023,1.0564-.0018,1.5845.0031.2363.0021.2378.01.2464.2457.0023.0659,0,.132,0,.198q0,8.4773-.0006,16.9543c0,.4724-.0468.4432.454.4433q5.1695.0011,10.3389.0009c.4816,0,.46-.0408.46.4405-.0007.4753.0032.9507-.0022,1.426-.0029.2575-.01.2583-.2721.2677-.0923.0032-.1848.0006-.2773.0007l-12.5572.0031h-.04c-.4013-.001-.4041-.001-.4042-.4182Q23.4831,17.1723,23.4837,12.3791Z" transform="translate(-1.5128 -1.7744)"></path>
                                    <path d="M12.0668,32.5354c0-1.4258-.0028-2.8515.0016-4.2773A1.253,1.253,0,0,1,12.9072,27a2.1745,2.1745,0,0,1,.62-.0975c1.9538-.0072,3.9077-.0027,5.8615-.0064a1.557,1.557,0,0,1,.7926.2068,1.0892,1.0892,0,0,1-.5176,2.0225c-1.5182.0043-3.0364.0017-4.5545.0017-.0924,0-.1853.0059-.2772-.0013-.1717-.0135-.234.0635-.2325.2314.0051.5808-.0006,1.1617.0031,1.7426.002.3.007.3059.3093.3067q1.8217.0047,3.6436.0032c.1188,0,.2381-.0056.3563.0035a1.1254,1.1254,0,0,1,1.0005.7242,1.101,1.101,0,0,1-.9868,1.4957c-.1443.012-.29.004-.4355.0041q-1.7822.0017-3.5644.0039c-.3191.0009-.3226.0066-.324.3331q-.0038.8516.001,1.703c.002.3161.0061.32.3355.32q2.1387.0025,4.2773.0012c.1584,0,.3169-.0012.4753.0026a1.1015,1.1015,0,0,1,1.1043,1.0622,1.147,1.147,0,0,1-1.0673,1.1591c-.2364.0166-.475.0019-.7126.0019-1.7822,0-3.5645.0056-5.3467-.0038a2.5583,2.5583,0,0,1-.8133-.1168,1.186,1.186,0,0,1-.782-1.1726c-.025-.4872-.0064-.9767-.0066-1.4651Q12.0664,34.0009,12.0668,32.5354Z" transform="translate(-1.5128 -1.7744)"></path>
                                    <path d="M50.8332,32.7946c0-1.1353-.0061-2.2707.0028-3.406.004-.5.0316-.9992.0616-1.498a1.1357,1.1357,0,0,1,.7927-.8991,2.2177,2.2177,0,0,1,.5829-.0868q2.9109-.0091,5.8218.0009a2.2059,2.2059,0,0,1,.62.0981A1.11,1.11,0,0,1,59.47,28.053a1.0843,1.0843,0,0,1-.77,1.04,1.6112,1.6112,0,0,1-.4316.0294q-2.2575.0027-4.5149.0012c-.46,0-.4687.0014-.46.4564.0085.4469.0372.8934.0559,1.34a1.126,1.126,0,0,1-.0046.158c-.0328.33-.0333.3357.3034.336q1.8812.0018,3.7623-.0053a1.4514,1.4514,0,0,1,.727.1446,1.0821,1.0821,0,0,1,.5811,1.1866,1.1131,1.1131,0,0,1-1.0657.8938c-.1575.0116-.3167-.0011-.4751-.0011q-1.703,0-3.4059.0008a2.7148,2.7148,0,0,0-.3554.0158.1409.1409,0,0,0-.0979.0919c-.0152.2994-.0284.5995-.0254.8991.004.4062.0193.8123.04,1.2179a.1779.1779,0,0,0,.1067.1308,1.9852,1.9852,0,0,0,.3548.0154c1.5314.001,3.0628.0043,4.5941-.0007a1.1012,1.1012,0,0,1,.506,2.1.9131.9131,0,0,1-.3844.0533c-1.5181.004-3.0363-.0016-4.5544.0069-.5261.0029-1.0518.0436-1.5779.0559a1.8842,1.8842,0,0,1-.9169-.1858,1.1146,1.1146,0,0,1-.5467-.6956,2.226,2.226,0,0,1-.0788-.6627C50.83,35.3821,50.8331,34.0883,50.8332,32.7946Z" transform="translate(-1.5128 -1.7744)"></path>
                                    <path d="M91.5415,26.7091a4.976,4.976,0,0,1,3.522,1.4363,5.618,5.618,0,0,1,1.21,2.0026,8.1645,8.1645,0,0,1,.4313,2.5411,6.4506,6.4506,0,0,1-.0936.899,6.077,6.077,0,0,1-.3839,1.5192,5.4542,5.4542,0,0,1-.503,1.0285,4.6447,4.6447,0,0,1-2.437,1.9714,4.2743,4.2743,0,0,1-2.17.2249,7.1137,7.1137,0,0,1-.8874-.1587,4.7958,4.7958,0,0,1-2.4608-1.8125,5.4242,5.4242,0,0,1-.837-1.8128,4.2879,4.2879,0,0,1-.2136-1.2766c.0207-.4309-.0113-.8644.0092-1.2953A5.7,5.7,0,0,1,88.256,28.228a4.5849,4.5849,0,0,1,2.0868-1.2986A10.7906,10.7906,0,0,1,91.5415,26.7091ZM94.2,32.4606c-.0441-.2515-.1136-.6373-.1789-1.0238a3.1843,3.1843,0,0,0-.9846-1.9219,2.0313,2.0313,0,0,0-2.9276.1613,3.5792,3.5792,0,0,0-.6743,1.2434,11.2982,11.2982,0,0,0-.2438,1.5531.5192.5192,0,0,0,.0137.1566c.0191.1694.0483.3383.0562.5082a3.559,3.559,0,0,0,.9659,2.3962,1.9133,1.9133,0,0,0,1.483.6273,2.0839,2.0839,0,0,0,1.7082-.9106A5.2283,5.2283,0,0,0,94.2,32.4606Z" transform="translate(-1.5128 -1.7744)"></path>
                                    <path d="M76.0056,32.9379a9.0367,9.0367,0,0,1,.43-2.9122,4.5565,4.5565,0,0,1,2.335-2.8034,5.0789,5.0789,0,0,1,2.9936-.4244,6.3027,6.3027,0,0,1,1.7649.5291,1.3257,1.3257,0,0,1,.7966,1.0991,1.0335,1.0335,0,0,1-.375.8785,1.1268,1.1268,0,0,1-1.3558.1616,4.5635,4.5635,0,0,0-1.3721-.4628,2.1276,2.1276,0,0,0-2.2356,1.1732,5.6246,5.6246,0,0,0-.3724,1.2338.4658.4658,0,0,0,.019.1334.714.714,0,0,0,.1078-.0335c.2354-.1158.4633-.25.7057-.3476a3.7607,3.7607,0,0,1,1.7716-.295,4.1766,4.1766,0,0,1,2.7145,1.0932,3.033,3.033,0,0,1,.8973,1.8407,4.7607,4.7607,0,0,1,.066.7433,3.7225,3.7225,0,0,1-2.3321,3.4474,5.3389,5.3389,0,0,1-1.3658.3424,5.5623,5.5623,0,0,1-1.6465-.0335,3.9167,3.9167,0,0,1-2.2967-1.2,4.66,4.66,0,0,1-1.1379-2.5129A15.6572,15.6572,0,0,1,76.0056,32.9379Zm4.4936,3.3074c.0792,0,.1585.0006.2376,0a1.6534,1.6534,0,0,0,1.6779-1.3792,1.4984,1.4984,0,0,0-.5628-1.5067A2.1244,2.1244,0,0,0,81.0731,33a2.16,2.16,0,0,0-1.7216.18,1.5992,1.5992,0,0,0-.2775,2.6079A1.8683,1.8683,0,0,0,80.4992,36.2453Z" transform="translate(-1.5128 -1.7744)"></path>
                                    <path d="M70.0716,26.9024c.7792,0,1.5584-.0065,2.3375.0034a4.8645,4.8645,0,0,1,.8227.0763,1.0739,1.0739,0,0,1,.8518,1.362,1.3967,1.3967,0,0,1-.3383.6528c-.7521.85-1.6287,1.5716-2.4167,2.3837-.0344.0355-.0661.0737-.099.1106a.6244.6244,0,0,0,.1586.0743,4.0407,4.0407,0,0,1,1.637.6521,2.47,2.47,0,0,1,.9923,1.3,3.664,3.664,0,0,1-.442,3.2813,3.39,3.39,0,0,1-1.9515,1.38,5.9838,5.9838,0,0,1-3.3449-.0412,4.8818,4.8818,0,0,1-1.8578-1.0135,1.1531,1.1531,0,0,1-.4475-.9731,1.2,1.2,0,0,1,.9-1.0852,1.2866,1.2866,0,0,1,1.23.3618,3.0594,3.0594,0,0,0,2.2275.73,2.1105,2.1105,0,0,0,.7845-.2352,1.31,1.31,0,0,0-.06-2.2819,2.0493,2.0493,0,0,0-.8672-.24,6.9185,6.9185,0,0,0-.8977-.0478,1.1231,1.1231,0,0,1-1.1518-.8357.9976.9976,0,0,1,.1739-.86,3.6037,3.6037,0,0,1,.4563-.5433c.5737-.5839,1.1555-1.16,1.7337-1.7393a1.5584,1.5584,0,0,0,.1613-.1741c.0683-.0934.0237-.1454-.0785-.153-.0919-.0068-.1847-.0035-.2771-.0035-.9641-.0005-1.9281.0014-2.8922-.0019A1.0452,1.0452,0,0,1,67,27.0144a1.8491,1.8491,0,0,1,.576-.107C68.4074,26.8959,69.24,26.9025,70.0716,26.9024Z" transform="translate(-1.5128 -1.7744)"></path>
                                    <path d="M1.5216,32.6065c0-1.4656.0026-2.9313-.0012-4.397a1.2089,1.2089,0,0,1,.5154-1.0566,1.5146,1.5146,0,0,1,.8956-.2557c1.3072.0032,2.6145.0014,3.9217.0013.713,0,1.4261-.0061,2.1391.0018a1.1918,1.1918,0,0,1,1.1916.86,1.1229,1.1229,0,0,1-1.008,1.4353c-.1179.0109-.2375.0048-.3563.0048q-2.1589.0008-4.3179.001c-.0924,0-.1853.0062-.2772-.0009-.172-.0133-.231.0629-.2292.2317.0064.6073-.0008,1.2148.0048,1.8222.0027.2917.0241.3132.3213.3141,1.1752.0033,2.35.0011,3.5256.0008.1584,0,.3169-.0046.4753-.0011a1.1043,1.1043,0,0,1,1.18,1.1247A1.13,1.13,0,0,1,8.4,33.8666a4.0307,4.0307,0,0,1-.5146.0044q-1.7428,0-3.4859-.0009c-.4459-.0006-.4009-.005-.4015.4152-.0015.9243-.0247,1.8494.0065,2.7727a1.2078,1.2078,0,0,1-1.24,1.2523A1.2872,1.2872,0,0,1,1.513,37.0431C1.5386,35.5646,1.5214,34.0854,1.5216,32.6065Z" transform="translate(-1.5128 -1.7744)"></path>
                                    <path d="M97.82,2.441c0,.1844.0052.369-.0043.5529-.002.0377-.0731.1082-.0882.1023-.05-.02-.1252-.0652-.128-.1051-.0156-.2231-.0112-.4476-.0182-.6715-.0009-.0287-.0284-.0566-.0435-.0849a.7268.7268,0,0,1-.0526.0541c-.1366.114-.1454.4073-.3508.3708-.1185-.021-.1986-.2539-.2983-.39-.0141-.0192-.0554-.0443-.06-.04-.0235.0218-.0548.0514-.0555.0791-.0058.2239-.0016.4481-.011.6718-.0014.0338-.0578.0652-.0887.0977-.0207-.03-.0589-.0593-.0593-.0893-.0049-.3557-.01-.7116-.0018-1.0671.0027-.1265.0927-.1708.1838-.0857a2.0718,2.0718,0,0,1,.2867.3636c.1016.151.1893.1635.2894.0147a1.3761,1.3761,0,0,0,.1447-.28c.0553-.1466.1809-.1373.2888-.1234.0317.0041.0632.1258.0682.1955.01.1443.0032.29.0032.4349Z" transform="translate(-1.5128 -1.7744)"></path>
                                    <path d="M95.609,2.561c0-.0659-.0041-.1322.0008-.1977.0149-.1985.0069-.3723-.2646-.3765-.0384-.0006-.0754-.0866-.1131-.1331.0522-.0261.1039-.0734.1568-.0748.25-.0067.5011-.0067.751.006.0457.0024.0878.0748.1315.1149a.6161.6161,0,0,1-.1122.0978.2421.2421,0,0,1-.1167.0122c-.1594-.0065-.2021.0833-.2.2242.0043.2373.01.4752-.004.7118-.0032.0543-.0819.1043-.1259.1564-.034-.0486-.0917-.0945-.0972-.1461a3.6888,3.6888,0,0,1-.0047-.3951Z" transform="translate(-1.5128 -1.7744)"></path>
                                </svg>
                            </div>
                            <h1 class="text-center display-4 font-weight-bold mb-2">
                                私密超頻
                            </h1>
                            <h1 class="text-center display-4 font-weight-bold d-flex justify-content-center align-items-center">
                                收
                                <span class="title-em-sm d-inline-block font-weight-bold mx-3">陰</span>
                                機
                            </h1>
                            <div class="version version-sm position-absolute">
                                <h2 class="font-weight-bold">4.0</h2>
                            </div>
                        </div>
                    </div>

                    <p class="h5 font-weight-normal text-justify">
                        marier Skincare全港最多人信賴的私密護理專家，引入至今超過千名真實用家見證 ^。
                    </p>

                    <p class="h5 font-weight-normal text-justify mb-3">
                        擺脫傳統收陰手術概念，私密超頻收陰機透過一次性探頭，深入女性私密處， 360度釋放熱能射頻，大約8分鐘的療程就可以刺激陰道周邊膠原蛋白再生與重組，活化細胞層，改善陰道鬆弛並提升尿道的支持力。療程非入侵性，相比傳統收陰手術，過程中沒有不適感，無恢復期；能改善陰道鬆弛、乾澀等問題，並減少分泌物的產生，對於年紀稍長的女性，還能改善了尿滲問題。
                    </p>


                    <div class="row no-gutters mb-1">
                        <div class="col d-flex justify-content-center justify-content-md-end">
                            <div class="position-relative">
                                <h2 class="position-relative"
                                    data-aos="fade"
                                    data-aos-duration="1000"
                                    data-aos-delay="1250">
                                    內陰收緊
                                </h2>
                            </div>
                        </div>
                        <div class="col d-flex justify-content-center">
                            <div>
                                <h2 data-aos="fade"
                                    data-aos-duration="1000"
                                    data-aos-delay="1500">
                                    外陰修型
                                </h2>
                            </div>
                        </div>
                        <div class="col-md d-flex justify-content-center justify-content-md-start">
                            <div>
                                <h2 data-aos="fade"
                                    data-aos-duration="1000"
                                    data-aos-delay="1500">
                                    改善尿滲
                                </h2>
                            </div>
                        </div>
                    </div>

                    <h3 class="text-center font-weight-bold mb-2"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="1250">
                        $3,400<br>首次體驗價 *
                    </h3>

                    <h3 class="text-center mb-3"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="1250">
                        <button class="btn btn-lg btn-light btn-make-appointment font-weight-bold"
                                v-on:click="scrollToForm">
                            立即預約
                        </button>
                    </h3>

                    <div class="d-flex flex-column justify-content-center align-items-center mb-4"
                         data-aos="flip-up"
                         data-aos-duration="1000"
                         data-aos-delay="1250">
                        <div class="h6 mb-1">瀏覽影片</div>
                        <div class="h6 mb-1">
                            <font-awesome-icon v-bind:icon="['far', 'chevron-double-down']"></font-awesome-icon>
                        </div>
                        <button class="position-relative btn-video-preview img-thumbnail"
                                v-on:click="isVideoModalOpen = true">
                            <img class="video-preview"
                                 src="../../../assets/campaign/intima/intima_video_preview.png">
                            <div class="position-absolute play-overlay w-100 h-100 d-flex justify-content-center align-items-center">
                                <h1 class="display-4 mb-0">
                                    <font-awesome-icon v-bind:icon="['far', 'play-circle']"></font-awesome-icon>
                                </h1>
                            </div>
                        </button>
                    </div>

                    <div>
                        <p class="text-center mb-0">
                            * 只限內陰或外陰
                        </p>
                        <p class="text-center">
                            * 資料及時數根據marier Skincare內部銷售數據
                        </p>
                    </div>
                </div>

                <div class="col-lg-4 order-0 order-lg-1 feature-img-wrapper text-center text-lg-left h-100 mb-5 mb-lg-0">
                    <img data-aos="slide-left"
                         data-aos-duration="1000"
                         class="feature-img d-none d-lg-inline"
                         alt="Intima"
                         src="../../../assets/campaign/intima/papaya.png">
                    <img data-aos="fade"
                         data-aos-duration="1000"
                         class="feature-img d-inline d-lg-none"
                         alt="Intima"
                         src="../../../assets/campaign/intima/papaya.png">
                </div>
            </div>
        </div>

        <modal-youtube-video video-src="https://www.youtube.com/embed/5TmPnUUV0XI"
                             v-model="isVideoModalOpen">
        </modal-youtube-video>
    </section>
</template>

<script>
    import ModalYoutubeVideo from "../ModalYoutubeVideo";

    export default {
        name: "IntimaHome",
        components: {
            ModalYoutubeVideo,
        },
        data () {
            return {
                isVideoModalOpen: false,
            };
        },
        methods: {
            scrollToForm () {
                document.getElementById("enquiry").scrollIntoView({
                    "behavior": "smooth"
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/variables";
    @import "~bootstrap/scss/mixins/_breakpoints";

    @import "./variables.scss";

    .home {
        min-height: auto !important;
        padding-top: 60px;

        background-image: url($background);
        background-color: $background-color;
        color: $text-color;

        .title {
            z-index: 1;

            & > .logo {
                top: 0;
                left: 10px;

                & > svg {
                    height: 35px;
                    width: auto;

                    @include media-breakpoint-up(md) {
                        height: 40px;
                    }

                    path {
                        fill: $text-color;
                    }
                }

                &.logo-md {
                    top: -37.5px;
                    left: -10px;
                }
            }

            & > h1 {
                white-space: nowrap;

                & > .title-em,
                & > .title-em-sm {
                    position: relative;
                    font-size: 9rem;

                    &:before {
                        display: block;
                        content: "";
                        width: 30px;
                        height: 30px;

                        position: absolute;
                        top: -5px;
                        left: -10px;

                        border-top: 2px solid $text-color;
                        border-left: 2px solid $text-color;
                    }

                    &:after {
                        display: block;
                        content: "";
                        width: 30px;
                        height: 30px;

                        position: absolute;
                        bottom: -5px;
                        right: -10px;

                        border-bottom: 2px solid $text-color;
                        border-right: 2px solid $text-color;
                    }
                }

                & > .title-em-sm {
                    font-size: 6rem;
                }
            }

            & > .version {
                top: 2.5px;
                right: -38px;

                &.version-md {
                    top: auto;
                    bottom: calc(50% - 2rem * 1.2 + 12px);
                }

                &.version-sm {
                    top: auto;
                    bottom: calc(50% - 1.5rem * 1.2 + 12px);
                    right: -24px;
                }
            }
        }

        .btn-make-appointment {
            color: $text-color-alt !important;
        }

        .feature-img-wrapper {
            .feature-img {
                max-height: 275px;
                width: auto;

                filter: drop-shadow(0 0 2rem rgba(255, 255, 255, 1));

                @include media-breakpoint-up(md) {
                    max-height: 300px;
                }

                @include media-breakpoint-up(lg) {
                    max-height: initial;
                    width: 75%;
                }
            }
        }

        .btn-video-preview {
            .video-preview {
                max-width: 150px;
            }

            .play-overlay {
                top: 0;
                left: 0;

                color: rgba(255, 255, 255, 1);
                background-color: rgba(255, 255, 255, 0.25);
            }
        }
    }
</style>
